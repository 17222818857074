<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
          >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>代理管理</el-breadcrumb-item>
          <el-breadcrumb-item>分润汇总</el-breadcrumb-item>
          <el-breadcrumb-item>服务商分润</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="分润规则">
              <el-select
                      v-model="where.obj.targetType"
                      placeholder="请选择"
                      filterable
                      clearable
              >
                <el-option label="全部" value="0"></el-option>
                <el-option label="全国服务商" value="1"></el-option>
                <el-option label="创客" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字">
              <el-input
                      v-model="where.obj.keyword"
                      placeholder="请输入姓名/手机号/ID"
                      clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="日期筛选">
              <el-date-picker
                      v-model="where.obj.month"
                      type="month"
                      placeholder="选择月">
                <i class="el-icon-date"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
              >条件筛选</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12"> </el-col>
            <el-col :span="12">
              <el-button
                      type="primary"
                      @click="exportData"
                      disabled
                      icon="el-icon-tickets"
              >导出数据</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-row style="display: inline-block">
          <el-col class="page-remark">
            <span>说明：</span>
            <p>
              分润规则：此处默认为服务商分润列表，名下BD发展的商家交易数据，以此汇总
            </p>
            <p>
              显示规则：代理分润为系统每天自动计算前一天的分润并汇总，此处默认显示前一天的分润，如显示更多，请使用筛选功能
            </p>
          </el-col>
          <el-col>
            <el-table
                    :data="list"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.1)"
                    @selection-change="handleSelectionChange"
            >
              <el-table-column prop="targetId" label="服务商ID"></el-table-column>
              <el-table-column prop="targetName" label="服务商姓名"></el-table-column>
              <el-table-column
                      prop="mobile"
                      label="手机账号"
              ></el-table-column>
              <el-table-column prop="targetType" label="类型"></el-table-column>
              <el-table-column prop="month" label="所属月份"></el-table-column>
              <el-table-column prop="tradeNum" label="交易笔数"></el-table-column>
              <el-table-column prop="tradeAmount" label="交易金额"></el-table-column>
              <el-table-column prop="sendHuiDou" label="送出惠豆"></el-table-column>
              <el-table-column prop="huiDoAmount" label="惠豆分润金额"></el-table-column>
              <el-table-column prop="juHeAmount" label="聚合分润金额（预计）"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-dropdown>
                <span class="el-dropdown-link"
                >操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                      ><el-button
                              type="text"
                              class="el-icon-s-shop"
                              size="medium"
                              @click="
                        $router.push({
                          name: 'serviceprovidersDetail',
                          query: { id: scope.row.targetId },
                        })
                      "
                      >分润明细</el-button
                      ></el-dropdown-item
                      >
                      <el-dropdown-item
                      ><el-button
                              type="text"
                              class="el-icon-arrow-down"
                              size="medium"
                              @click="
                        $router.push({
                          name: 'extensionworkers',
                          query: { id: scope.row.targetId },
                        })
                      "
                      >下级BD</el-button
                      ></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col>
            <div class="admin_table_main_pagination">
              <el-pagination
                      @current-change="currentChange"
                      @size-change="handleSizeChange"
                      background
                      :total="totalData"
                      :page-size="pageSize"
                      :current-page="currentPage"
                      :page-sizes="[10, 20, 50, 100]"
                      layout="sizes, prev, pager, next, total, jumper"
              ></el-pagination>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {
        list: [],
        date: "",
        row: {},
        loading: false,
        totalData: 0, // 总条数
        pageSize: 20,
        currentPage: 0,
        selectId: "",
        openAreaList: "",
        where: {
          page: 0,
          limit: 0,
          obj:{
            month:'',
            operateArea:'',
            keyword:'',
          },
        },
      };
    },
    methods: {
      listData() {
        this.loading = true;
        this.where.page = this.currentPage;
        this.where.limit = this.pageSize;
        this.$post(this.$api.serviceProvidersList, this.where).then((res) => {
          this.totalData = res.data.total;
          this.list = res.data.records;
          this.loading = false;
        });
      },
      handleSelectionChange(e) {
        let ids = [];
        e.forEach((v) => {
          ids.push(v.id);
        });
        this.selectId = ids.join(",");
      },
      currentChange(e) {
        this.currentPage = e;
        this.listData();
      },
      handleSizeChange(e) {
        this.pageSize = e;
        this.listData();
      },
      orderDate(obj) {
        if (obj) {
          this.where.month = obj;
          console.log(obj);
        }
      },
      exportData() {
        // console.log("exportData");
      },
      getOpenAreaList() {
        const postData = {
          isOpen: 1,
          type: 2,
          areaCode: "",
        };
        this.$postFormData(this.$api.districtOpenAreaList, postData).then(
                (res) => {
                  if (res.code == 1) {
                    this.openAreaList = res.data;
                  }
                }
        );
      },
    },
    created() {
      this.listData();
      this.getOpenAreaList();
    },
  };
</script>
<style lang="scss" scoped>
  .endDate {
    color: #c0c4cc;
  }
  .el-image {
    height: 100px;
    width: 100px;
  }
  .el-select {
    width: 120px;
  }
</style>
